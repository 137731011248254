import FacebookSvg from '~/components/assets/svg/FacebookSvg';
import InstagramSvg from '~/components/assets/svg/InstagramSvg';
import LinkedInSvg from '~/components/assets/svg/LinkedInSvg';
import MailSvg from '~/components/assets/svg/MailSvg';
import YoutubeSvg from '~/components/assets/svg/YoutubeSvg';
import PlaystoreSvg from '~/components/assets/svg/PlaystoreSvg';
import PhonePlaySvg from '~/components/assets/svg/PhonePlaySvg';
import IosPlayStore from '~/components/assets/svg/IosPlayStore';
import PhoneIosSvg from '~/components/assets/svg/PhoneIosSvg';
import Login from '~/components/assets/svg/Login';
import { Link, Outlet, useLocation } from "@remix-run/react";
import React from "react";
import { OpenFr8LogoSvg } from "~/components/assets/svg/OpenFr8LogoSvg";
import { Phone } from 'lucide-react';

export const shareLikn = () => {
    const message = "Hi FR8, I want to know more about your transport service in UAE.";
    const contact = '+97142576798'
    const url = `https://api.whatsapp.com/send?phone=${contact}&text=${encodeURI(message)}&app_absent=0`;
    window.open(url, '_blank');
  };

const Layout = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const {pathname} = useLocation()
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const onClickSignUp = () => {
    const url = import.meta.env.VITE_GCC_APP_URL || 'https://openfr8.com/app'
    window.open(url + '/login','_blank')
  }

  const menu = {home: '/', blog: '/blog', rates: '/truck-rates'}

  const isHome = (pathname === menu.home)
  const isBlog = (pathname.startsWith(menu.blog))
  const isRates = (pathname === menu.rates)

  return (
    <div className='min-h-screen relative flex flex-col'>
      <nav className="px-6 shadow-md fixed left-0 top-0 right-0 bg-white z-50">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          {/* Logo */}
          <div className="flex items-center">
           <Link to={menu.home}><OpenFr8LogoSvg /></Link> 
          </div>

          {/* Menu Items */}
          <div className="hidden md:flex space-x-8">
            <a href={menu.home} className={isHome ? 'text-secondary' : ''}>Home</a>
            <a href={menu.blog}className={isBlog ? 'text-secondary' : ''}>Blog</a>
            <a href={menu.rates} className={isRates ? 'text-secondary' : ''}>Truck Rates</a>
          
          </div>

          <div className='flex gap-2 items-center'>
          <div className='flex gap-2 items-center'>
          <PlaystoreSvg className='hidden md:block' />
          <IosPlayStore className='hidden md:block' />
          <PhonePlaySvg className='md:hidden' />
          <PhoneIosSvg className='md:hidden' />
          <Login className='md:hidden' />
            <button type="button" onClick={onClickSignUp} className="hidden md:block text-white bg-secondary font-medium rounded-lg focus:ring-2  text-sm px-2 md:px-5 py-2">Login / Signup</button>
          </div>

          
          </div>

          {/* Burger Menu for Mobile */}
          <div className="md:hidden">
            <button onClick={toggleMenu} className="focus:outline-none">
              <svg
                className="h-6 w-6"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden mt-2">
            <a href={menu.home} className={`${isHome ? 'text-secondary' : ''} block py-2 px-4`}>Home</a>
            <a href={menu.blog}className={`${isBlog ? 'text-secondary' : ''}  block py-2 px-4`}>Blog</a>
            <a href={menu.rates} className={`${isRates ? 'text-secondary' : ''}  block py-2 px-4`}>Truck Rates</a>
          </div>
        )}
      </nav>

      <main className='pt-[80px] flex-1'>
        <Outlet />
      </main>

      <footer className='bg-black h-fit flex items-center justify-center'>
      <div className='flex flex-col sm:flex-row items-center gap-4 container mx-auto px-8 py-14 justify-between'>
          <div className='text-slate-300 text-sm text-center sm:text-left'>
            <div className='flex flex-row mb-2 sm:justify-start justify-center  py-2 items-center sm:items-start gap-2'>
              <Phone size={17} />
              <p className='font-medium'>+971 42576798</p>
            </div>
            <h4 className='mb-2'>OpenFR8 L.L.C.-FZ,</h4>
            <p className='text-slate-500 text-xs'>Office 201, 2nd floor, Falcon house,</p>
            <p className='text-slate-500  text-xs'>Dubai Industrial Park 1, Dubai, U.A.E.</p>
          </div>
          <div className='text-center sm:text-right'>
            <div className='flex flex-row gap-4 items-center justify-end'>
              <FacebookSvg />
              <LinkedInSvg />
              <YoutubeSvg />
              <InstagramSvg />
              <MailSvg />
            </div>
            <p className='text-slate-400 text-xs mt-3'>Copyright © 2024 FR8</p>
          <Link to="/terms" className='text-slate-400 text-xs mt-3'>Terms of Service</Link>
          </div>
        </div>
      </footer>
      <button
        className={`${"fixed rounded-full bottom-2 right-3"
          } flex items-center space-x-2 btn outline-btn px-4 py-2 border-slate-100 bg-white shadow-md border-t`}
        onClick={shareLikn}
      >
        <svg
          className="w-8 h-8"
          viewBox="0 0 91 91"
          fill="none"
          xmlns="http:www.w3.org/2000/svg"
        >
          <path
            d="M0 90.7628L6.38597 67.4626C2.45946 60.6451 0.366762 52.9 0.388336 44.9823C0.388336 20.1719 20.5818 0 45.3922 0C57.4306 0 68.7355 4.68161 77.2142 13.1819C85.7144 21.6821 90.396 32.987 90.3744 45.0038C90.3744 69.8142 70.181 89.9861 45.3706 89.9861H45.349C37.8196 89.9861 30.4197 88.0876 23.8395 84.5062L0 90.7628ZM24.9614 76.3512L26.3206 77.171C32.0593 80.5797 38.6394 82.3704 45.349 82.392H45.3706C65.974 82.392 82.7587 65.6288 82.7587 45.0038C82.7587 35.015 78.8754 25.6302 71.8206 18.5538C64.7658 11.4775 55.3595 7.59413 45.3706 7.59413C24.7456 7.59413 7.96089 24.3573 7.96089 44.9823C7.96089 52.037 9.92414 58.9192 13.6781 64.8737L14.5626 66.2976L10.7871 80.0835L24.9614 76.3512Z"
            fill="black"
            fillOpacity="0.24"
          />
          <path
            d="M2.0415 88.3582L8.13514 66.1356C4.38521 59.6371 2.40371 52.2438 2.40371 44.7013C2.40371 21.0512 21.6647 1.81152 45.2935 1.81152C56.7777 1.81152 67.5374 6.28587 75.6339 14.3823C83.7303 22.4787 88.1833 33.2598 88.1833 44.7226C88.1833 68.3728 68.9223 87.6125 45.2935 87.6125H45.2722C38.092 87.6125 31.0395 85.8014 24.7754 82.3924L2.0415 88.3582Z"
            fill="url(#paint0_linear_4686_9432)"
          />
          <path
            d="M0.486084 89.9124L6.79278 66.9015C2.91501 60.1687 0.848293 52.5197 0.869599 44.7002C0.869599 20.1979 20.8124 0.276367 45.3148 0.276367C57.2038 0.276367 68.3683 4.89986 76.7417 13.2946C85.1365 21.6893 89.76 32.8539 89.7386 44.7215C89.7386 69.2239 69.7958 89.1454 45.2935 89.1454H45.2722C37.8362 89.1454 30.5281 87.2705 24.0297 83.7336L0.486084 89.9124ZM25.1376 75.6798L26.4799 76.4894C32.1474 79.8558 38.6459 81.6243 45.2722 81.6456H45.2935C65.6411 81.6456 82.2175 65.0905 82.2175 44.7215C82.2175 34.8567 78.3823 25.5884 71.4151 18.5999C64.4479 11.6114 55.1583 7.77622 45.2935 7.77622C24.9245 7.77622 8.34815 24.3313 8.34815 44.7002C8.34815 51.6674 10.287 58.4642 13.9944 64.3447L14.8679 65.751L11.1393 79.3658L25.1376 75.6798Z"
            fill="white"
          />
          <path
            d="M34.1928 26.1C33.3619 24.2464 32.4883 24.2037 31.7 24.1824C31.0608 24.1611 30.3151 24.1611 29.5693 24.1611C28.8236 24.1611 27.6305 24.4381 26.6077 25.546C25.585 26.654 22.73 29.3386 22.73 34.8143C22.73 40.2688 26.7143 45.5528 27.2682 46.2985C27.8222 47.0442 34.9599 58.6136 46.231 63.0666C55.6058 66.774 57.5234 66.0282 59.5475 65.8365C61.5929 65.6447 66.1098 63.1519 67.0473 60.5525C67.9635 57.9531 67.9635 55.7372 67.6865 55.2685C67.4095 54.7998 66.6638 54.5228 65.5559 53.9688C64.4479 53.4148 58.9935 50.7302 57.9708 50.3467C56.9481 49.9632 56.2024 49.7927 55.4779 50.9007C54.7322 52.0086 52.6016 54.5015 51.9624 55.2472C51.3232 55.9929 50.6627 56.0781 49.5548 55.5242C48.4468 54.9702 44.8673 53.7984 40.6274 50.0058C37.3249 47.0655 35.0877 43.4221 34.4485 42.3142C33.8093 41.2063 34.3846 40.6097 34.9386 40.0557C35.4286 39.5657 36.0465 38.756 36.6005 38.1168C37.1544 37.4776 37.3462 37.0089 37.7084 36.2632C38.0706 35.5175 37.9001 34.8783 37.6232 34.3243C37.3462 33.7703 35.1729 28.2946 34.1928 26.1V26.1Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4686_9432"
              x1="45.1111"
              y1="88.3521"
              x2="45.1111"
              y2="1.80668"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#20B038" />
              <stop offset="1" stopColor="#60D66A" />
            </linearGradient>
          </defs>
        </svg>
        <span className="hidden sm:block px-1 text-green-600 normal-case">{'Chat with us'}</span>
      </button>
    </div>

  );
};

export default Layout;
